/*Modal*/
.modalBG{position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: #00000066; z-index: 99999; display: none;}
.modal{position: relative; top: 50px; width: 1000px; height: 600px; background: #ffffff; border: 1px solid #000; margin: 0 auto;}
.showModal{display: block; overflow-y: auto;}
.modalContent{display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; color: #485860; font-family: 'Montserrat', sans-serif; align-items: center; height: 100%;}
.modalContent .logo{text-align: center;}
.modalContent img{max-width: 230px; margin: 0 auto;}
.modalContent .info{text-align: left;}
.modalContent .info .categoria{max-width: 400px;}
.modalContent .info a{color: #485860;}
.modalIcon{cursor: pointer; position: absolute; right: 10px; top: 10px;}
.close path{stroke: #fff;}
.modalRedesSociais{display: flex;align-items: center;}
.modalRedesSociais li{display: inline-block; padding-right: 10px;}
.modalRedesSociais li a {width: 25px; height: 25px; display: block;}
/*Modal Cadastro*/
.identificacao{height: 50px; background: #63a269;font-family: 'Montserrat', sans-serif; color: #fff;}
.identificacao h2{text-align: left; text-transform: uppercase;letter-spacing: 2px; padding: 10px 0px 10px 20px;}
.headerForm {text-align: center;font-family: "Montserrat",sans-serif;padding: 20px 0;}
.headerForm h2 {color: #707070;text-transform: uppercase;letter-spacing: 2px;padding-bottom: 5px;}
.headerForm span {color: #6ba968;font-size: 14px;}
.modalContentContato input, .modalContentContato select{font-size: 18px; color: #818486; font-family: 'Montserrat', sans-serif;
    border: 0; border-bottom: 1px solid #818486; background-color: transparent; width: 100%; margin-bottom: 48px;}
.modalContentContato input, .modalContentContato select{margin-bottom: 0px;}
.modalContentContato select {color: #818486;font-size: 16px!important;font-family: "Montserrat",sans-serif;margin-bottom: 30px!important;}
.modalContentContato span{font-size: 15px; margin-bottom: 48px; display: block;}
.modalContato{position: relative; top: 50px; width: 500px; height: auto; background: #ffffff; margin: 0 auto 100px;}
.modalContentContato{display: grid; grid-template-columns: 1fr; padding: 30px 10px 10px 10px; color: #485860; 
  font-family: 'Montserrat', sans-serif; align-items: center; height: 100%;}
.modalContentcontato h3{text-align: center;}
.mbInput {margin-bottom: 30px !important; position: relative;}
.mbInput input {padding-left: 25px;z-index: 2;position: relative;}
.mbInput input:focus {border-color: #6ba968;}
.mbInput input:focus+label svg {color: #6ba968!important;}
.mbInput input::-webkit-input-placeholder {color: #818486; font-size: 16px;}
.mbInput input:-ms-input-placeholder {color: #818486; font-size: 16px;}
.mbInput input::placeholder {color:#818486; font-size: 16px;}
.mbInput label {display: block;cursor: pointer;position: absolute;top: 50%;transform: translateY(-50%);left: 0;z-index: 1;}
.inputRadios {background: #bed4bb;color: #508154;padding: 30px 25px!important;}
.inputCheckbox div {display: grid;grid-template-columns: 10px 1fr;grid-gap: 20px;gap: 20px;}
.inputCheckbox p, .inputCheckbox p span {font-size: 11px;}
.inputCheckbox div, .inputRadios div {font-size: 17px!important;}
.inputCheckbox {border: none!important;}
.inputCheckbox, .inputRadios {padding: 10px 5px;}
.inputCheckbox input, .inputRadios input {width: 20px!important;margin-bottom: 0!important;}
.infosInput {position: absolute;text-align: right;width: 100%;}
.infosInput p {font-size: 13px;text-decoration: underline;}
.lastInput {margin-bottom: 10px !important; position: relative;}
.checkIcon{margin: 0 auto;}
.modalContatoCadastro{height: 400px !important;}
.formButtonIdentifica {background-color: #63a36a;border: 1px solid #63a36a;color: #fff;
text-transform: uppercase;font-size: 21px;width: 100%;height: 60px;border-radius: 0;justify-self: flex-end;
transition: background-color .2s;margin-bottom: 0px;}
.formButtonIdentifica:hover {background-color: #2f6735;border: 1px solid #2f6735;}
.msg {display: flex; align-items: baseline; justify-content: space-between;}
.center{text-align: center;}
.empresas{font-size: 15px; text-transform: uppercase; color: #818486;font-family: "Montserrat", sans-serif; border: 1px solid #818486; border-top: 0; overflow-y: scroll; height: 12rem;}
.empresas div:hover {background-color: #818486; color: #ffffff; cursor: pointer;}

@media (min-width: 768px) and (max-width: 991px){
.modal{width: 700px;}
.modalContent h1{font-size: 20px;}
.modalContent p{font-size: 16px;}
}

@media (max-width: 767px){
.modalContent{grid-template-columns: 1fr; gap: 10px; height: 100%;}
.modal{width: 90%;}
.modalContent .logo, .modalContent .logo img{max-width: 180px; margin: 0 auto;}
.modalContent .info .categoria {max-width: 100%;}
.modalContent h1{font-size: 18px;}
.modalContent p{font-size: 16px;}
.modalContent .info {text-align: center;padding:20px;}
.modalContato{width: 90%;}
.modalContentContato select{font-size: 12px !important;}
.identificacao h2 {font-size: 12px;padding: 17px 0; text-align: center;}
.mbInput {margin-bottom: 10px!important;}
.empresas{font-size: 12px;}
.inputRadios {margin-bottom: 15px!important; margin-top: 30px; padding: 20px 25px !important;}
.mbInput input::-webkit-input-placeholder {color: #818486; font-size: 12px;}
.mbInput input:-ms-input-placeholder {color: #818486; font-size: 12px;}
.mbInput input::placeholder {color:#818486; font-size: 12px;}
.modalRedesSociais{display: flex;align-items: center; justify-content: center;}
}