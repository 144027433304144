#cinemaProgramacaoPage{background-size: cover; background-position: center top; background-repeat: no-repeat;}
.cinemaBanner{color: #fff; font-family: 'Montserrat', sans-serif;}
.cinemaFilter img{width:100%; height:auto; object-fit: cover; position: relative; z-index: -1;}
.banner{display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px; position: absolute; z-index: 2;}
.banner p {max-width: 786px;font-size: 22px;line-height: 27px;margin-bottom: 20px; margin-top: 10px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner button a {display: flex;height: 62px;text-align: center;align-items: center;justify-content: center;color: #fff;}
.banner button:hover a {color: #485860 !important;}
.bannerIcon{position: relative; left: 17px; top: 5px;}
.cinemaPageContent{display: grid; grid-template-columns: 1fr 1fr; gap: 12px; padding: 20px 0px;}
.link {margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; 
display: flex; text-align: center;align-items: center;justify-content: center; transition: background-color 0.2s; position: absolute;
z-index: 2;}
.link:hover{background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.cinemaBanner .shoppingInfo{display: flex; justify-content: flex-end; gap: 29px;}
.cinemaBanner .shoppingInfo span{border-left: 1px solid #fff; height: 40px;}
.cinemaBanner .shoppingInfo a{color: #ffffff;}
/*Content*/
.content {display: grid;grid-template-columns: 1fr 1fr;grid-gap: 10px;gap: 10px;align-items: center;text-align: center;
justify-items: center;font-family: "Montserrat",sans-serif;}
.contentBox{display: flex; flex-direction: column; align-items: center;}
.contentBox p{max-width: 100% !important; padding: 0px 30px 20px 30px; color: #485860;font-size: 18px; line-height: 25px;}
.contentBox h2 {color: #485860;font-size: 45px;line-height: 50px;max-width: 520px;font-weight: 400;text-transform: uppercase;padding-bottom: 20px;}
.contentBox a {margin: 20px 0;background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;line-height: 25px;width: 500px;height: 60px;border-radius: 0;
display: flex;text-align: center;align-items: center;justify-content: center;transition: background-color .2s;}
.contentBox a:hover {background-color: #485860;color: #ffffff;border: 1px solid #485860;}
.contentImg{object-fit: cover; height: 800px;}
/*Página Programação*/
#programacao{padding: 15px 0px;}
.filmesContent{display: grid; grid-template-columns: repeat(5, 1fr); gap: 30px; font-family: 'Montserrat', sans-serif;}
.filmesContent .titulo, .erro{font-family: 'Montserrat', sans-serif; color: #485860;font-size: 20px; font-weight: 700; line-height: 25px; text-transform: uppercase; height: 70px; padding: 10px 0px;}
.filmesContent .linkButton{color: #ff6d6d;text-transform: uppercase;text-align: left;font-size: .8rem;font-weight: 700;
margin-top: .6rem;border: 2px solid #ff6d6d;padding: .3rem 1rem;display: inline-block;}
.filmesContent .genero{text-transform: uppercase; color: #485860; display: flex; justify-content: space-between;}
.filmesContent .classificacao{background-color: #207ddd; color: #ffffff; font-weight: 700; padding: 5px;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1600px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.contentBox h2{font-size: 40px;}
.contentBox p{font-size: 16px;}
.contentImg{object-fit: cover; height: 840px;}
.cinemaPageContent p {font-size: 16px;}
.cinema-img{object-fit: cover;height: 840px;}
.filmesContent .titulo, .erro{font-size: 16px};
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.contentBox h2{font-size: 40px;}
.contentBox p{font-size: 16px;}
.contentImg{object-fit: cover; height: 890px;}
.cinemaPageContent p {font-size: 16px;}
.cinema-img{object-fit: cover;height: 890px;} 
.filmesContent .titulo, .erro{font-size: 16px};
}
@media(max-width: 1199px){
.banner{max-width: 100%;}
.banner h1{font-size: 2em; line-height: 35px;}
.banner p {font-size: 16px;}
.content{grid-template-columns: 1fr;}
.contentBox{margin: 0 10px;}
.contentImg{object-fit:cover; height: 72vh !important}
.cinemaPageContent p {font-size: 12px;}
.filmesContent{grid-template-columns: repeat(4, 1fr);}
.filmesContent .titulo, .erro{font-size: 15px};
.cinemaBanner .shoppingInfo{display: flex; justify-content: space-between; gap: 0px; max-width: 650px;}


}

@media (min-width: 992px) and (max-width: 1199px) {
.contentBox h2{font-size: 2rem; line-height: 37px;}
.contentBox p{font-size: 16px; line-height: 21px;}
.contentImg{object-fit: cover; width: 100%; height: 58vh;}
}

@media (min-width: 768px) and (max-width: 991px){
  .contentBox h2{font-size: 2rem; line-height: 37px;}
  .contentBox p{font-size: 16px; line-height: 21px;}
  .contentBox a {width: 360px;font-size: 15px;}
  .filmesContent{grid-template-columns: repeat(2, 1fr);}
  .filmesContent .titulo, .erro{font-size: 15px; line-height: 18px; height: 60px;}
  .filmesContent .genero{font-size: 14px;}
  .cinemaBanner .shoppingInfo{display: none;}
}

@media (max-width: 767px){
  .banner span {font-size: 14px;}
  .link{width: 70%;height: 45px;font-size: 14px;}
  .cinemaBanner .shoppingInfo {display: none;}
  .contentBox h2{font-size: 1.4rem; line-height: 28px;}
  .contentBox p{font-size: 14px;}
  .contentBox a {width: 100%; height: 45px; font-size: 14px;}
  .cinemaPageContent{grid-template-columns: 1fr;}
  .filmesContent{grid-template-columns: 1fr;}
  .banner hr{margin-bottom: 0px;}
  .filmesContent .titulo, .erro{font-size: 15px; line-height: 18px; height: 60px;}
  .filmesContent .genero{font-size: 14px;}
}