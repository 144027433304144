
#contatoPage{padding: 44px 0px; position: relative;}
.contatoBanner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: 0.766fr 1fr;
align-items: center;}
.contatoBanner .bannerMenu{top: 0px;}
.contatoBanner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*FORM*/
#formContato{padding: 50px 0px 0px 0px;}
.formContatoContent{display: grid; grid-template-columns: 1.4fr 0.01fr 1fr; gap: 25px; font-family: 'Montserrat', sans-serif; padding-bottom: 40px;}
.formContatoContent h1, .formContatoContent strong, .formContatoContent div{color: #818486; font-size: 20px; line-height: 24px;}
#formContato form input::-webkit-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
#formContato form input:-ms-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
#formContato form input::placeholder {color:#818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
#formContato form{padding: 54px 0px 0px 0px;}
#formContato form input, #formContato form select,
#formContato form textarea{font-size: 18px !important;}
#formContato form textarea{font-size: 18px; color: #818486; font-family: 'Montserrat', sans-serif; 
  border: 1px solid #818486;
width: 100%; height: 150px; margin-bottom: 20px; padding: 10px;}
#formContato form input, #formContato form select{
font-size: 18px;
color: #818486;
font-family: "Montserrat",sans-serif;
border: 0;
border-bottom: 1px solid #818486;
background-color: transparent;
width: 100%;
margin-bottom: 48px;
}
label{font-size: 16px; color: #818486;}
#formContato form .half{display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;}
.checkbox{font-size: 15px; color: #818486; margin-bottom: 20px;}
.checkbox input{width: 15px !important; height: 15px; margin-bottom: 20px !important;}
.checkbox span{padding-left: 5px; position: relative; top: 2px; font-size: 16px;}
#formContato form .formButton{background-color: #63a36a;border: 1px solid #63a36a;
color: #ffffff;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
#formContato form .formButton:hover{background-color: #2f6735; border: 1px solid #2f6735;}
.formButtonIdentifica{background-color: #63a36a;border: 1px solid #63a36a;
  color: #ffffff;text-transform: uppercase;font-size: 21px;width: 100%;height: 60px;border-radius: 0px;
  justify-self: flex-end; transition: background-color 0.2s; margin-bottom: 40px;}
.formButtonIdentifica:hover{background-color: #2f6735; border: 1px solid #2f6735;}
.formWidth{width: 500px; margin: 0 auto;}
.formWidth span{background: #fff;width: 30px;display: block;text-align: center; margin: 0 auto;
top: -13px; position: relative; font-size: 13px;}
.inputRadios, .inputCheckbox{padding: 10px 5px; margin-bottom: 28px;}
.inputCheckbox{border: none !important;}
.inputRadios div, .inputCheckbox div{font-size: 17px !important;}
.inputRadios input, .inputCheckbox input{width: 20px !important; margin-bottom: 0px !important;}
.politicas{text-decoration: underline; cursor: pointer;}
.senha input{margin-bottom: 10px !important;}
.senha p{font-size: 16px; cursor: pointer; margin-bottom: 48px; color: #63a36a; text-decoration: underline; text-align: right;}
.senha p:hover{color: #2f6735; font-weight: bold;}
.contatoInfo iframe{border: none; width: 100%;}
.line{padding: 20px 0px;}
.line span{border-left: 1px solid #e8e8e8; height: 100%; display: block;}
.comercial{padding-bottom: 50px;}
.comercial h1{color: #818486; font-size: 20px; line-height: 24px; font-family: 'Montserrat', sans-serif; }
.comercial h2{color: #818486; font-size: 20px; line-height: 24px; font-family: 'Montserrat', sans-serif; }
.comercial strong, .comercial p, .comercial a{color: #485860;font-size: 20px;line-height: 25px;
max-width: 100%;padding-bottom: 20px;font-family: 'Montserrat', sans-serif;}
.news:checked:after {width: 15px !important;height: 15px !important;top: -2px !important;left: 0px !important;}

/*Responsivo*/
@media (min-width: 1200px) and (max-width: 1399px){
  .formContatoContent h1, .formContatoContent strong, .formContatoContent div, .comercial h1{font-size: 16px;}
  #formContato form input, #formContato form select, label {font-size: 16px;}
  #formContato form input::-webkit-input-placeholder {font-size: 16px;}
  #formContato form input:-ms-input-placeholder {font-size: 16px;}
  #formContato form input::placeholder {font-size: 16px;}
  .news:checked:after{top: -3px !important;}
}
@media (min-width: 992px) and (max-width: 1199px){
.formWidth {width: 360px;}
#formContato form input::-webkit-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
#formContato form input:-ms-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
#formContato form input::placeholder {color:#818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
.news:checked:after{top: -3px !important;}
}

@media (min-width: 768px) and (max-width: 991px){
  .news:checked:after{top: -3px !important;}
}


@media(max-width: 1199px){
  .formContatoContent h1, .formContatoContent strong, .formContatoContent div, .comercial h1{font-size: 16px;}
  #formContato form input, #formContato form select, label {font-size: 16px;}
  .formWidth {width: 300px;}
  .formButtonIdentifica{font-size: 16px;}
  #formContato form input::-webkit-input-placeholder {color: #818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  #formContato form input:-ms-input-placeholder {color: #818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  #formContato form input::placeholder {color:#818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  .news:checked:after{top: -2px;}
}
@media (max-width: 767px){
  #formContato form .half{grid-template-columns: repeat(1, 1fr); gap: 0px;}
  #formContato form input, #formContato form select{margin-bottom: 28px;}
  #formContato form input::-webkit-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  #formContato form input:-ms-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  #formContato form input::placeholder {color:#818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  .comercial strong, .comercial p, .comercial a {font-size: 14px;}
  .contatoBanner{grid-template-columns: 1fr; text-align: center;}
  .comercial{text-align: center;}
  .formContatoContent{grid-template-columns: 1fr; text-align: center; gap: 0px;}
  .line{display: none;}
  #formContato form .formButton{width: 100%; margin-bottom: 20px;}
  .formWidth {width: 100%;}
  .checkbox{text-align: start;padding-left: 4px;}
  label{display: table;}
  .news:checked:after{top: -3px !important;}
}

