.boxCookies.hide {
    display: none !important;
}



.boxCookies {
    position: fixed;
    bottom: 10px;
    z-index: 999;
    padding: 30px;
    background: rgba(107, 169, 104, 0.9);
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 10px;
    
  
}

.boxCookies .msgCookies,
.boxCookies .btnCookies {
    padding: 10px;
    color: #ffffff;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;

    a {
        text-decoration: underline;
        color: #ffffff;  
    }
}

.boxCookies .btnCookies {
    background: #ffffff;
    cursor: pointer;
    align-self: normal;
    border-radius: 10px;
    border: 0;
    color: #6ba968;
    width: 196px;
    height: 54px;
    align-self: center;

    &:hover {
        background: #f3eeee;
    }

}

.btns {
    display: flex;
    gap: 10px;
}

@media (min-width: 1200px) and (max-width: 1399px){
    .boxCookies {
        max-width: 1200px;
    }

    .boxCookies .msgCookies,
    .boxCookies .btnCookies {
        font-size: 14px;
    }
}

@media screen and (max-width: 1199px) {
    .boxCookies {
        max-width: 100%;
        flex-direction: column;
        margin: 0 10px;
        gap: 10px;
    }

    .boxCookies .msgCookies,
    .boxCookies .btnCookies {
        font-size: 14px;
        text-align: center;

       
        br {
            display: none;
        }
        
    }
}