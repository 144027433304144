.logoFooter{width: 200px; height: auto; margin-bottom: 20px; background-color: #ffffff;}
.footerContent{display: grid; grid-template-columns: repeat(4, 1fr); gap: 50px; font-family: 'Montserrat', sans-serif;
color: #000000; padding: 60px 0px;}
.footerContent .title{font-size: 16px; color: #6ba968; text-transform: none; display: block; padding-bottom: 20px;}
.footerContent .title a{color: #6ba968; text-decoration: none;}
.footerContent p {font-size: 16px; color: #000000; padding: 0px; line-height: 20px;}
.footerContent span{text-transform: uppercase; color: #000000;}
.footerContent ul {padding: 0px;}
.footerContent ul li a{line-height: 20px; font-size: 16px; color: #000000;}
.footerContent ul li a:hover{color: #6ba968;}
.Redessociais li{display: inline-block; padding-right: 12px;}
.Redessociais li a:hover svg {color: #6ba968 !important;}

.footerIcone{position: relative; top: 5px;}
.footerContent2{display: flex; padding: 10px 0; gap: 20px; font-family: 'Montserrat', sans-serif; color: #000000; align-items: center; justify-content: center;}
.footerContent2 p {font-size: 14px; color: #000000; padding: 10px 0px; line-height: 20px;}
.logoEna{justify-self:right; width: 160px;}
.logoEna img{width: 100%; height: auto;}

.horarioTitle {padding-bottom: 0px !important;}
.horarios{margin-bottom: 20px !important;}
.horarios p {padding: 0px !important;}
.enderecos{margin-bottom: 20px !important;}
.enderecos p {padding: 0px !important;}

@media (min-width: 1400px) and (max-width: 1599px){
.footerContent{gap: 90px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
.footerContent{gap: 20px; grid-template-columns: repeat(4, 1fr);}
.footerContent p , .footerContent .title .footerContent ul li{font-size: 14px;}
}

@media (min-width: 992px) and (max-width: 1199px){
.footerContent{grid-template-columns: repeat(2, 1fr);}
.logoFooter{position: relative; top: -25px; right: 0px;}
}


@media (min-width: 768px) and (max-width: 991px){
.footerContent{grid-template-columns: repeat(2, 1fr);}
.logoFooter{position: relative; top: -25px; right: 0px;}
}


@media (max-width: 767px){
.hideMobile{display: none;}
.logoFooter{width: 170px;}
.footerContent{grid-template-columns: 1fr; padding: 0px; gap: 10px; padding: 10px 0}
.footerContent div{margin-bottom: 0px !important;}
.footerContent p, .footerContent .title, .footerContent span, .footerContent ul li a, .footerContent2 p {font-size: 13px;}
.footerContent .title{padding-top: 0px;}
.footerContent .horarios {padding-bottom: 20px;}
.footerContent .enderecos {padding-bottom: 20px;}
.footerIcone{top: 6px;}
.sociais{margin: 30px 0px !important;}
.Redessociais{padding: 20px 0px !important;}
.footerContent2 p{padding-top: 30px;}
.footerContent2{display: grid; grid-template-columns: 1fr; gap: 7px; text-align: center;}
.logoEna {justify-self: center; height: 45px;}
}
