.aconteceNoParqueBanner {
 color: #fff;
 font-family: 'Montserrat', sans-serif;
}
.banner {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 height: 100%;
}
.aconteceNoParqueFilter img {
 width: 100%;
 height: auto;
 object-fit: cover;
 position: relative;
 z-index: -1;
}
.banner h1 {
 font-size: 60px;
 line-height: 73px;
 font-weight: 700;
 text-transform: uppercase;
 margin: 15px 0px;
 position: absolute;
 z-index: 2;
}
.banner hr {
 background-color: #ffffff;
 color: #ffffff;
 border: none;
 height: 1px;
 margin-bottom: 20px;
}
.banner p {
 max-width: 786px;
 font-size: 22px;
 line-height: 27px;
}
#informativo p,
#informativo strong,
#informativo span,
#informativo a {
 color: #485860 !important;
 font-size: 18px !important;
 line-height: 25px !important;
 max-width: 100%;
 padding-bottom: 0px !important;
 font-family: 'Montserrat', sans-serif !important;
 background-color: transparent !important;
}
#informativo a {
 color: #485860 !important;
 text-decoration-line: underline !important;
}
.BannerMobile {
 display: none !important;
}

.gallery {
 //display: flex;
 //flex-direction: row;
 gap: 20px;
 margin: 20px 0 40px 0;
 grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
 display: grid;
}

.gallery div {
 width: 500px;
 height: 500px;
}

.gallery div img {
 width: 100%;
 height: 100%;
 object-fit: cover;
 cursor: pointer;
}

.videos {
 //display: flex;
 //flex-direction: row;
 gap: 20px;
 margin: 20px 0 40px 0;
 grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
 display: grid;
}

.videos div {
 position: relative;
 overflow: hidden;
 width: 100%;
 padding-top: 56.25%;
}

.videos div iframe {
 position: absolute;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
 width: 100%;
 height: 100%;
}

.galleryTitle {
 color: #485860;
 font-size: 18px;
 line-height: 25px;
 font-family: Montserrat, sans-serif;
 margin: 20px 0;
}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px) {
 .banner {
  max-width: 1600px;
 }
 .banner h1 {
  font-size: 50px;
  line-height: 60px;
 }
 .banner p {
  font-size: 16px;
 }
 #informativo p,
 #informativo strong,
 #informativo span {
  font-size: 16px !important;
 }
}

@media (min-width: 1200px) and (max-width: 1399px) {
 .banner {
  max-width: 1400px;
 }
 .banner h1 {
  font-size: 50px;
  line-height: 60px;
 }
 .banner p {
  font-size: 16px;
 }
 #informativo p,
 #informativo strong,
 #informativo span {
  font-size: 16px !important;
 }
}

@media (max-width: 1199px) {
 .banner {
  max-width: 100%;
 }
 .banner h1,
 .champions-content h1 {
  font-size: 2em;
  line-height: 36px;
  text-align: center;
 }
 .banner p {
  font-size: 16px;
 }
 #informativo p,
 #informativo strong,
 #informativo span {
  font-size: 14px !important;
 }
}

@media (max-width: 767px) {
 #informativo p,
 #informativo strong,
 #informativo span {
  font-size: 14px !important;
 }
 .BannerDesk {
  display: none !important;
 }
 .BannerMobile {
  display: block !important;
 }

 .gallery,
 .videos {
  grid-template-columns: 1fr !important;
 }
 .gallery div {
  width: 100%;
  height: auto;
 }
}
