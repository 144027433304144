.clubeDoParqueBanner{color: #fff; font-family: 'Montserrat', sans-serif;}
.clubeDoParqueFilter img{width:100%; height:auto; object-fit: cover; position: relative; z-index: -1;}
.banner{display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p{max-width: 786px;font-size: 22px; line-height: 27px;  margin-bottom: 20px;}
.banner a {margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
  text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; 
  display: flex;text-align: center;align-items: center;justify-content: center;transition: background-color 0.2s; position: absolute; z-index: 2;}
.banner a:hover {background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}

/*Beneficios*/
#beneficios{padding: 15px 0px;}
#beneficios p{font-size: 20px; line-height: 35px; color: #485860; font-family: 'Montserrat', sans-serif; margin-bottom: 30px;}
.beneficiosContentGrid{display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px; align-items: flex-start; font-family: 'Montserrat', sans-serif;}
.beneficiosContentGrid .bg{background-color: #BED6BB; text-align: center; padding: 20px; width: 100%; cursor: pointer;}
.beneficiosContentGrid .bg p{font-size: 18px; line-height: 21px; padding: 10px 0px;}
.beneficiosContentGrid .bg button{font-weight: 700; color: #485860; border: 0; background: transparent;}
.beneficiosConfira {
  background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s; padding: 20px; width: 420px; display: block; margin: 0 auto;
}
/*Confira*/
#confira h1{color:#485860; font-family: 'Montserrat', sans-serif; font-size: 20px; line-height: 25px; font-weight: 700;
text-transform: uppercase; margin-bottom: 20px;}
.confiraContent{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.confiraContent div{border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase;}
.confiraContent div p{font-size: 22px; font-weight: 700;}
.confiraContent div span{font-size: 16px; text-transform: none;}
.logoClube {height: 18em; border: none !important; padding: 0px !important; align-items: center; display: flex;}
.logoClube img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%);}
.modalIconX {cursor: pointer;position: absolute;right: 30px;top: 10px;z-index: 99999;}


/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1600px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.confiraContent div p {font-size: 18px;}
.confiraContent div span {font-size: 15px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.confiraContent div p {font-size: 16px;}
.confiraContent div span {font-size: 15px;}
#confira h1{font-size: 17px;}
.beneficiosContentGrid{align-items: center;}
#beneficios p {font-size: 16px;}
.beneficiosContentGrid .bg p {font-size: 15px; line-height: 18px;}
}
@media(max-width: 1199px){
.banner{max-width: 100%; margin: 0 0px; padding-bottom: 25px}
.banner h1{font-size: 2em; line-height: 35px;}
.banner p {font-size: 16px;}
.confiraContent div p {font-size: 16px;}
.confiraContent div span {font-size: 14px;}
#confira h1{font-size: 16px;}
#beneficios p {font-size: 15px;}
.beneficiosContentGrid .bg p {font-size: 15px; line-height: 18px;}
.beneficiosContentGrid{grid-template-columns: 1fr }
}

@media (min-width: 992px) and (max-width: 1199px){
.beneficiosContentGrid .bg p {font-size: 14px; line-height: 18px;}
}

@media (min-width: 768px) and (max-width: 991px){
.confiraContent{grid-template-columns: repeat(2, 1fr);}
}

@media (max-width: 767px){
.banner span {font-size: 14px;}
.banner a {width: 100%;height: 45px;font-size: 14px;}
.confiraContent{grid-template-columns: 1fr;}
.beneficiosContentGrid{grid-template-columns: 1fr }
.beneficiosConfira {width: 100%;  display: block;
  text-align: center;}
}