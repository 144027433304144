@keyframes run {
  0% {
    right: -1000px;
  }
  50% {
    right: 0px;
  }
  100% {
    right: 0px;
  }
}

.topBar{background-color: #6ba968; font-family: 'Montserrat', sans-serif; font-size: 15px;}
.topBar .container {display: grid; grid-template-columns: 1fr 1fr; gap: 20px; align-items: center; height: 30px;}
.topBar .container div:last-of-type{justify-self: right;}
.topBar .container div ul li{display: inline-block; padding: 3px 10px 0px 0px}
.topBar .container div ul li a{color: #ffffff; display: block;}
.topBar .container div ul li:last-of-type{padding-right: 0px;}
.topBar .container div ul li svg {position: relative; top: 2px; left: 0px;}
.icone{position: relative; top: 2px; left: -10px;}
.cadastrese{padding-right: 50px !important;}
/*Modal*/
.show-modal{overflow-y: auto;}
.modal-topBar{height: auto !important;}
.identificação{height: 50px; background: #63a269;font-family: 'Montserrat', sans-serif; color: #fff;}
.voltar{height: 50px; background: #c9c9c9;font-family: 'Montserrat', sans-serif; color: #fff; cursor: pointer;}
.identificação h2{text-align: center;text-transform: uppercase;padding: 10px 0px; letter-spacing: 2px;}
.voltar h3{text-align: center;text-transform: uppercase;padding: 13px 0px; letter-spacing: 2px;}
.mb-input {margin-bottom: 30px !important; position: relative;}
.last-input {margin-bottom: 10px !important; position: relative;}
.modal-topBar-content span{text-align: center; text-decoration: underline; cursor: pointer;}
.modal-topBar-content button{margin-bottom: 0px !important;}
.modal-topBar-content input{padding-left: 25px; z-index: 2; position: relative;}
.modal-topBar-content input:focus + label svg{color: #6ba968 !important;}
.modal-topBar-content input:focus{border-color: #6ba968;}
.cadastrar{background-color: #535353;border: 1px solid #535353;color: #ffffff;
text-transform: uppercase;font-size: 21px;width: 100%;height: 60px;border-radius: 0px;justify-self: flex-end;
transition: background-color 0.2s; margin-bottom: 0px;}
.cadastrar:hover{background-color: #303130;border: 1px solid #303130;}
.mb-input label, .last-input label{ display: block; cursor: pointer; position: absolute;top: 50%;transform: translateY(-50%); left: 0px; z-index: 1;}
.view{width: 100%; z-index: 3 !important }
.view svg{position: absolute; right: 0px; top: -15px !important;}
.view:hover svg{color: #6ba968 !important;}
.voltar svg{position: absolute; top: 60px; left: 10px;}
.header-form{text-align: center; font-family: 'Montserrat', sans-serif; padding: 20px 0px;}
.header-form h2{color: #707070; text-transform: uppercase; letter-spacing: 2px; padding-bottom: 5px;}
.header-form span{color: #6ba968; font-size: 14px;}
.input-radios{background: #bed4bb; color: #508154; padding: 30px 25px !important;}
.input-radios h3{text-align: left !important; font-size: 20px;}
input[type="radio"] {border: 0px;width: 100%;height: 0.7em; position: relative; top: 3px; }
input[type='radio']:checked:after {width: 18px;height: 18px;border-radius: 15px;top: -4px;left: 1px;
position: relative;background-color: #508154;content: '';display: inline-block;visibility: visible;}
.infos-input{position: absolute; text-align: right; width: 100%;}
.infos-input p{font-size: 13px; text-decoration: underline;}
.input-checkbox p, .input-checkbox p span{font-size: 13px; /*color: #6ba968;*/}
.input-checkbox div{display: grid; grid-template-columns: 10px 1fr; gap: 20px;}
input[type=checkbox] {transform: scale(1.5); position: relative;
  top: 4px;}
input[type=checkbox]:checked:after {width: 20px;height: 20px;border-radius: 0px;top: 4px;left: 0px;
  position: relative;background-color: #508154;content: ''; color: #fff; display: inline-block;visibility: visible;}
#g-recaptcha {transform: scale(1.46);transform-origin: 0 0; margin-bottom: 70px;}
.fail{font-family: 'Montserrat', sans-serif; height: 30px; padding: 5px 10px; width: 310px; text-align: center; 
color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; display: none; transition: 1s; position: fixed; right: -1000px;}
.fail-show{display: block; animation: run 5s 1;}
/*Area Logada*/
.logado{width: 150px; position: absolute; top: 30px; padding: 0px; background: #fff; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);}
.logado li{display: block !important; padding: 5px; }
.logado li a:hover, .logado li:hover svg{color: #6ba968 !important; font-weight: 700;}
.logado li a{color: #485860 !important; font-size: 13px !important;}
.topBarSubmenu{width: 130px;position: absolute;top: 30px;padding:0px;background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 40%);z-index: 2}
.topBarSubmenu li{padding: 5px !important;}
.topBarSubmenu li a{color: #485860 !important;}
.topBarSubmenu li a:hover{color: #6ba968 !important; font-weight: bold;}

@media (max-width: 1199px){
.cadastrar {font-size: 16px;}
.fail{width: 100%;}
}
@media (max-width: 767px){
  .topBar{display: none;}
  .identificação h2{font-size: 12px; padding: 17px 0px;}
  .mb-input {margin-bottom: 10px !important;}
  .input-radios, .input-checkbox {padding: 20px 5px;}
}

@media (max-width: 580px){
  input[type=checkbox]:checked:after {
    top: 11px;
  }
}

@media (max-width: 414px){
  input[type=checkbox]:checked:after {
    top: 18px;
  }
}

@media (max-width: 320px){
  input[type=checkbox]:checked:after {
    top: 25px;
  }
}

