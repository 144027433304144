.swiperSlide a{height: 100%; display: block;}
.swiperSlide img{width:100%; height:auto; object-fit: cover; position: relative; z-index: -1;}
.slides{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1800px; margin: 0 auto;
  padding-bottom: 20px; padding-left: 10px; padding-right: 10px;}
.slides span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.swiperSlide h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.slideContent{display: grid; grid-template-columns: 1fr 1fr; gap: 12px; padding: 20px 0px;}
.slideContent p{max-width: 694px; font-size: 22px; line-height: 27px;}
.slideContent a{margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; 
display: flex;text-align: center;align-items: center;justify-content: center;  transition: background-color 0.2s;}
.slideContent a:hover {background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.slide-icon{position: relative; left: 17px; top: 5px;}
.shopping-info{display: flex; justify-content: flex-end; gap: 29px;}
.shopping-info p{font-size: 20px; line-height: 24px;}
.shopping-info span{border-left: 1px solid #fff; height: 40px;}
.slideMobile{display: none !important;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .slideContent{grid-template-columns: 1fr 1fr;}
  .swiperSlide h1 {font-size: 50px; line-height: 60px;}
  .slideContent p {max-width: 568px; font-size: 16px;}
  .slide-icon{left: 0px}
  .slides{max-width: 1600px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
  .swiperSlide h1{font-size: 30px;}
  .slideContent{grid-template-columns: 1fr 1fr;}
  .swiperSlide h1 {font-size: 50px; line-height: 60px;}
  .slideContent p {max-width: 568px; font-size: 16px;}
  .slide-icon{left: 0px}
  .slides{max-width: 1400px;}
  .shopping-info{gap: 17px;}
}

@media(max-width: 1199px){
  .slides{max-width: 100%; margin: 0 10px;}
  .slide-icon{left: 0px}
}

@media (min-width: 992px) and (max-width: 1199px){
  .swiperSlide  h1{font-size: 2em; line-height: 23px;}
  .slideContent{grid-template-columns: 1fr 1fr;}
  .slideContent p {max-width: 100%;font-size: 16px;}
  .shopping-info{gap: 24px;}
}

@media (min-width: 768px) and (max-width: 991px){
  .swiperSlide  h1{font-size: 2em; line-height: 23px;}
  .slideContent{grid-template-columns: 1fr 1fr;}
  .slideContent p {max-width: 100%;font-size: 16px;}
  .slideContent a{width: 240px; font-size: 15px;}
  .shopping-info{gap: 8px;}
}

@media (max-width: 767px){
  .slider{padding-bottom: 40px;}
  .slides {margin: 0 10px;}
  .swiperSlide span{font-size: 14px;}
  .swiperSlide h1 {font-size: 1.3em; line-height: 30px; margin: 5px 0px;}
  .slideContent {grid-template-columns: 1fr;}
  .slideContent p {max-width: 100%; font-size: 14px; line-height: 25px; margin: 0px;}
  .slideContent a {width: 100%; height: 45px; font-size: 14px;}
  .shopping-info {display: flex;justify-content: space-between; gap: 0px;}
  .shopping-info p {font-size: 14px; margin: 0; line-height: 19px;}
  .slide-icon{width: 28px; top: 0px;}
  .shopping-info{display: none !important;}
  .slideDesk{display: none !important;}
  .slideMobile{display: block !important;}
}


@media (max-width: 320px){
  .shopping-info{gap: 17px;}
  .shopping-info p {font-size: 11px; margin: 0; line-height: 19px;}
}