.accordionList{list-style: none; margin: 0; padding: 0; font-family: 'Montserrat', sans-serif;}
.accordionList li{padding: 12px 9px; margin: 14px 0px 0px 0px; border: 1px solid #707070; display: flex; align-items: center;}
.accordionList li b{color: #377138; font-size: 20px; line-height: 24px; font-weight: 400; text-transform: uppercase;}
.accordionList li span{padding: 0px 9px 0px 0px;}
.accordionList div{color: #818486; font-size: 18px; line-height: 30px; font-weight: 400; border: 1px solid #707070; border-top: 0; padding: 12px 20px;}
.accordionList div {
    ul {
        list-style: disc !important;

        li {
            border: none !important; padding: 6px 9px;  margin: 0px 20px; display: list-item;
        }
    }
}
.iconAccordion{margin-left: auto;}
.open {display: block;}
.close {display: none;}


@media(max-width: 1199px){
    .accordionList div {font-size: 14px;}
}

@media(max-width: 767px){
    .accordionList li b{font-size: 15px;}
}
