.modalBG {
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 background: #00000066;
 z-index: 99999;
 display: none;
}
.modal {
 position: relative;
 top: 50px;
 width: auto;
 height: 700px;
 background: transparent;
 margin: 0 30px;
}

.modal img {
 width: 100%;
 height: 100%;
 object-fit: contain;
}

.showModal {
 display: block;
 overflow-y: auto;
}
.modalIcon {
 cursor: pointer;
 position: absolute;
 right: 0px;
 top: 0px;
}
.modalIcon path {
 stroke: #ffffff;
}
.Content {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100%;
}

@media (max-width: 767px) {
 .modal {
  width: 90%;
 }
}
