.SEO {
 display: none;
}

/*Life-center*/

.lifeCenterContent {
 display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 10px;
 align-items: center;
 text-align: center;
 justify-items: center;
 font-family: 'Montserrat', sans-serif;

 h2 {
  color: #485860;
  font-size: 45px;
  line-height: 50px;
  max-width: 561px;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 20px;
 }

 p,
 strong,
 span {
  color: #485860;
  font-size: 18px;
  line-height: 25px;
  max-width: 561px;
  padding-bottom: 20px;
 }

 a {
  margin: 20px auto;
  background-color: transparent;
  border: 1px solid #485860;
  color: #485860;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 25px;
  width: 207px;
  height: 62px;
  border-radius: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
   background-color: #485860;
   color: #fff;
   border: 1px solid #485860;
  }
 }
}

/*Cinema*/

#cinema {
 padding-top: 0px;
 margin-top: 10px;
}

.cinemaContent {
 font-family: 'Montserrat', sans-serif;
 background-size: 100% auto;
 height: 640px;

 p,
 strong,
 span {
  max-width: 1091px;
  font-size: 19px;
  line-height: 25px;
 }

 span {
  text-transform: uppercase;
 }

 h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0px;
 }

 a {
  margin: 20px 0px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 25px;
  width: 354px;
  height: 62px;
  border-radius: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
   background-color: #ffffff;
   color: #485860;
   border: 1px solid #ffffff;
   font-weight: 400;
  }
 }

 .filter {
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
 }

 .filmes {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 10px;
  padding-bottom: 20px;
 }
}

/*Novidades*/

.novidadesContent {
 display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 10px;
 font-family: 'Montserrat', sans-serif;

 div {
  height: 540px;
  background-repeat: no-repeat;
  background-size: 100% auto;
 }

 span {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
 }

 h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0px;
 }

 p {
  color: #ffffff;
  font-size: 19px;
  line-height: 25px;
  padding-bottom: 20px;
  height: 26px;
 }

 a {
  margin: 20px 0px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 27px;
  width: 207px;
  height: 60px;
  border-radius: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: background-color 0.2s;

  &:hover {
   background-color: #ffffff;
   color: #485860;
   border: 1px solid #ffffff;
  }
 }

 .card {
  max-width: 690px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 50px;
  padding-bottom: 20px;
 }

 .novidadesFilter {
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
 }
}

/*Nossas Lojas*/

#lojas {
 padding-top: 0px;
 margin-top: 10px;
}
.lojasContent {
 font-family: 'Montserrat', sans-serif;
 height: 320px;

 h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0px;
 }

 p,
 strong,
 span {
  max-width: 735px;
  font-size: 19px;
  line-height: 25px;
 }

 a {
  margin: 20px 0px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 27px;
  width: 207px;
  height: 60px;
  border-radius: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  &:hover {
   background-color: #ffffff;
   color: #485860;
   border: 1px solid #ffffff;
   font-weight: 400;
  }
 }

 .lojas {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 10px;
  padding-bottom: 20px;
 }

 .lojasFilter {
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
 }
}

/*Diferenciais*/

.diferenciaisContent {
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 gap: 10px;
 font-family: 'Montserrat', sans-serif;

 div {
  height: 320px;
  background-repeat: no-repeat;
 }

 h1 {
  font-size: 30px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
 }

 p,
 strong,
 span {
  color: #ffffff;
  font-size: 19px;
  line-height: 25px;
  padding-bottom: 20px;
  height: 76px;
 }

 a {
  color: #ffffff;
 }

 .card {
  max-width: 470px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
 }

 .diferenciaisFilter {
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
 }
}

/*Newsletter*/
#newsletter {
 padding-top: 0px;
 margin-top: 10px;
 background-color: #edf5ed;
}
.newsletterContent {
 text-align: center;
 font-family: 'Montserrat', sans-serif;
 padding: 35px 0px;

 p {
  color: #485860;
  font-weight: 400;
  font-size: 16px;
 }

 button {
  margin: 20px 0px;
  background-color: #6ba968;
  border: 1px solid #6ba968;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  width: 120px;
  height: 38px;
  border-radius: 0px;
  transition: background-color 0.2s;

  &:hover {
   background-color: #679665;
   color: #ffffff;
   border: 1px solid #679665;
   font-weight: 400;
  }
 }

 .newsIcone {
  margin-bottom: 10px;
 }
}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px) {
 /*Life-center*/

 .lifeCenterContent {
  h2 {
   font-size: 40px;
  }

  p,
  strong,
  span {
   font-size: 16px;
   max-width: 471px;
   margin: 0 auto;
  }
 }

 /* Cinema */

 .cinemaContent {
  height: 53vh;
  background-size: 100% auto;
  background-position: 50%;
  background-repeat: no-repeat;

  p {
   font-size: 16px;
  }

  h1 {
   font-size: 30px;
  }

  .filmes {
   max-width: 1600px;
  }
 }

 /*Novidades*/

 .novidadesContent {
  div {
   height: 53vh;
   background-size: 100% auto;
   background-position: top;
   background-repeat: no-repeat;
  }

  h1 {
   font-size: 30px;
  }

  .card {
   padding-left: 15px;
  }
 }

 /*Nossas Lojas*/

 .lojasContent {
  height: 33vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   font-size: 16px;
  }

  h1 {
   font-size: 30px;
  }

  .lojas {
   max-width: 1600px;
  }
 }

 /*Diferenciais*/

 .diferenciaisContent {
  div {
   height: 43vh;
   background-size: cover;
  }

  p {
   padding-bottom: 0px;
   height: 76px;
  }

  p,
  strong,
  span {
   font-size: 16px;
  }

  h1 {
   font-size: 23px;
  }

  .card {
   padding-left: 10px;
   max-width: 100%;
  }
 }
}

@media (max-height: 799px) {
 .novidadesContent {
  div {
   height: 60vh !important;
   background-size: cover !important;
  }
 }
}

@media (min-height: 800px) and (max-height: 850px) {
 .novidadesContent {
  div {
   height: 50vh !important;
   background-size: cover !important;
  }
 }
}

@media (min-width: 1200px) and (max-width: 1399px) {
 /*Life-center*/

 .lifeCenterContent {
  h2 {
   font-size: 40px;
  }

  p,
  strong,
  span {
   font-size: 16px;
   max-width: 471px;
   margin: 0 auto;
  }
 }

 /* Cinema */

 .cinemaContent {
  height: 45vh;
  background-size: 100% auto;
  background-position: 50%;
  background-repeat: no-repeat;

  p {
   font-size: 16px;
  }

  h1 {
   font-size: 30px;
  }

  .filmes {
   max-width: 1400px;
   padding-bottom: 20px;
  }
 }

 /*Novidades*/

 .novidadesContent {
  div {
   height: 43vh;
   background-size: 100% auto;
   background-position: top;
   background-repeat: no-repeat;
  }

  h1 {
   font-size: 30px;
  }

  .card {
   padding-left: 15px;
   padding-bottom: 20px;
  }
 }

 /*Nossas Lojas*/

 .lojasContent {
  height: 33vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   font-size: 16px;
  }

  h1 {
   font-size: 30px;
  }

  .lojas {
   max-width: 1400px;
   padding-bottom: 20px;
  }
 }

 /*Diferenciais*/

 .diferenciaisContent {
  div {
   height: 33vh;
   background-size: cover;
  }

  p {
   padding-bottom: 0px;
   height: 76px;
  }

  p,
  strong,
  span {
   font-size: 16px;
  }

  h1 {
   font-size: 20px;
  }

  .card {
   padding-left: 10px;
   max-width: 100%;
  }
 }

 /*Newsletter*/

 .newsletterContent {
  p {
   font-size: 15px;
  }
 }
}

@media (max-width: 1199px) {
 /* Life Center */

 .lifeCenterContent {
  grid-template-columns: 1fr;
 }

 /* Cinema */

 .cinemaContent {
  .filmes {
   max-width: 100%;
   margin: 0 10px;
   padding-bottom: 0px;
  }
 }

 .lojasContent {
  /*Nossas Lojas*/

  .lojas {
   max-width: 100%;
   margin: 0 10px;
   padding-bottom: 0px;
  }
 }
}

@media (min-width: 992px) and (max-width: 1199px) {
 /*Life-center*/

 .lifeCenterContent {
  h2 {
   font-size: 2em;
   line-height: 37px;
   max-width: 100%;
  }

  p,
  strong,
  span {
   font-size: 16px;
   line-height: 21px;
   max-width: 98%;
  }

  img {
   object-fit: cover;
   width: 100%;
   height: 58vh;
  }
 }

 /* Cinema */

 .cinemaContent {
  height: 43vh;
  background-size: 100% auto;
  background-position: 50%;
  background-repeat: no-repeat;

  p {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  h1 {
   font-size: 2em;
   line-height: 23px;
  }

  .filmes {
   max-width: 1200px;
   padding-bottom: 20px;
  }
 }

 /*Novidades*/

 .novidadesContent {
  div {
   height: 37vh;
   background-size: 100% auto;
   background-position: top;
   background-repeat: no-repeat;
  }

  h1 {
   font-size: 2em;
   line-height: 23px;
  }

  .card {
   max-width: 100%;
   padding: 0px 10px;
  }
 }

 /*Nossas Lojas*/

 .lojasContent {
  height: 53vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  h1 {
   font-size: 2em;
   line-height: 23px;
  }

  .lojas {
   max-width: 1200px;
   padding-bottom: 20px;
  }
 }

 /*Diferenciais*/

 .diferenciaisContent {
  grid-template-columns: repeat(2, 1fr);

  div {
   height: 53vh;
   background-size: cover;
  }

  p {
   padding-bottom: 0px;
   height: 76px;
  }

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  h1 {
   font-size: 2em;
   line-height: 50px;
  }

  .card {
   max-width: 100%;
   padding: 0px 10px;
  }
 }

 /*Newsletter*/

 .newsletterContent {
  p {
   font-size: 15px;
  }
 }
}

@media (min-width: 768px) and (max-width: 991px) {
 /*Life-center*/

 .lifeCenterContent {
  h2 {
   font-size: 2em;
   line-height: 37px;
   max-width: 100%;
  }

  p,
  strong,
  span {
   font-size: 16px;
   line-height: 21px;
   max-width: 80%;
   margin: 0 auto;
  }

  button {
   width: 147px;
   font-size: 15px;
  }
 }

 /* Cinema */

 .cinemaContent {
  height: 53vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  button {
   width: 240px;
   font-size: 15px;
  }

  h1 {
   font-size: 2em;
   line-height: 23px;
  }
 }

 /*Novidades*/

 .novidadesContent {
  div {
   height: 22vh;
   background-size: 100% auto;
   background-position: top;
   background-repeat: no-repeat;
  }

  h1 {
   font-size: 2em;
   line-height: 29px;
  }

  .card {
   max-width: 100%;
   padding: 0px 10px;
  }
 }

 /*Nossas Lojas*/

 .lojasContent {
  height: 53vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  h1 {
   font-size: 2em;
   line-height: 23px;
  }
 }

 /*Diferenciais*/

 .diferenciaisContent {
  grid-template-columns: repeat(2, 1fr);

  div {
   height: 32vh;
   background-size: cover;
   background-position: 50%;
   background-repeat: no-repeat;
  }

  p {
   padding-bottom: 0px;
   height: 76px;
  }

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 16px;
   line-height: 27px;
  }

  h1 {
   font-size: 1.8em;
   line-height: 50px;
  }

  .card {
   max-width: 100%;
   padding: 0px 10px;
  }
 }
}

@media (max-width: 767px) {
 /*Life-center*/

 .lifeCenterContent {
  grid-template-columns: 1fr;

  .content {
   margin: 0 10px;
  }

  h2 {
   font-size: 1.4em;
   line-height: 28px;
   max-width: 100%;
  }

  p,
  strong,
  span {
   font-size: 14px;
   padding-bottom: 10px;
   max-width: 100%;
   margin: 0 auto;
  }

  a {
   width: 100%;
   height: 45px;
   font-size: 14px;
  }
 }

 /* Cinema */

 .cinemaContent {
  height: 100vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  span {
   font-size: 14px;
  }

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 14px;
   padding-bottom: 0px;
  }

  a {
   width: 100%;
   height: 45px;
   font-size: 14px;
  }

  h1 {
   font-size: 1.3em;
   line-height: 30px;
   margin: 5px 0px;
  }

  .filmes {
   margin: 0 10px;
  }
 }

 /*Novidades*/

 .novidadesContent {
  grid-template-columns: 1fr;

  span {
   font-size: 14px;
  }

  div {
   height: 37vh;
   background-size: 100% auto;
   background-position: top;
   background-repeat: no-repeat;
  }

  a {
   width: 100%;
   height: 45px;
   font-size: 14px;
  }

  h1 {
   font-size: 1.3em;
   line-height: 30px;
   margin: 5px 0px;
  }

  .card {
   max-width: 100%;
   padding-left: 0px;
   padding-bottom: 0px;
   margin: 0 10px;
  }
 }

 /*Nossas Lojas*/

 .lojasContent {
  height: 50vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 14px;
   padding-bottom: 0px;
  }

  h1 {
   font-size: 1.3em;
   line-height: 30px;
   margin: 5px 0px;
  }

  a {
   width: 100%;
   height: 45px;
   font-size: 14px;
  }

  .lojas {
   margin: 0 10px;
  }
 }

 /*Diferenciais*/

 .diferenciaisContent {
  grid-template-columns: 1fr;

  div {
   height: 50vh;
   background-size: cover;
   background-position: 50%;
   background-repeat: no-repeat;
  }

  p {
   padding-bottom: 0px;
   height: 47px;
  }

  p,
  strong,
  span {
   max-width: 100%;
   font-size: 14px;
   padding-bottom: 0px;
  }

  h1 {
   font-size: 1.3em;
   line-height: 30px;
  }

  .card {
   max-width: 100%;
   padding-left: 0px;
   padding-bottom: 0px;
   margin: 0 10px;
  }
 }

 /*Newsletter*/

 .newsletterContent {
  p {
   font-size: 14px;
  }
 }
}
