.modalBG{position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: #00000066; z-index: 99999; display: none;}
.modal{position: relative; top: 50px; width: 400px; height: 300px; background: #ffffff; border: 1px solid #000; margin: 0 auto;}
.showModal{display: block; overflow-y: auto;}
.modalIcon{cursor: pointer; position: absolute; right: 10px; top: 10px;}
.modalIcon path{stroke: #63a36a;}
.NewsContent{display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;}
.NewsContent svg{margin-bottom: 30px;}
.NewsContent form{display: flex; flex-direction: column; width: 90%;}
.NewsContent form input {padding: 10px 0px 10px 5px; margin-bottom: 20px; color: #485860; 
    font-family: 'Montserrat', sans-serif; font-size: 16px; border: 1px solid #818486;}
.NewsContent form input:focus{border-color: #63a36a;}
.NewsContent form input::-webkit-input-placeholder {color: #818486; font-size: 16px;}
.NewsContent form input:-ms-input-placeholder {color: #818486; font-size: 16px;}
.NewsContent form input::placeholder {color:#818486; font-size: 16px;}
.NewsContent form button {background-color: #63a36a;border: 1px solid #63a36a;color: #fff;
text-transform: uppercase;font-size: 21px;width: 100%;height: 43px;border-radius: 0; transition: background-color .2s; }
.NewsContent form button:hover {background-color: #2f6735;border: 1px solid #2f6735;}
.sucesso {padding: 20px 0px;display: flex;align-items: center;justify-content: center;color: #485860;}
.sucesso svg{margin-bottom: 0px;}

@media (max-width: 767px){
.modal{width: 90%;}
}