.Header{position: fixed; width: 100%; height: 218px; top: 0; z-index: 3; background-color: #ffffff;}
.logo{width: 207px; height: auto; margin: 0 auto; padding: 5px 0px;}
hr{background-color:#e8e8e8; color:#e8e8e8; border: none; height: 1px;}
header ul{font-family: 'Montserrat', sans-serif; font-size: 15px; display: flex;
flex-direction: row; justify-content: space-between; padding: 10px 0px;}
header ul li{position: relative;}
header ul li a, header ul li span{text-transform: uppercase; color: #485860;}
header ul li svg{position: relative; top: 2px; left: 0px;}
header ul li a:hover, header ul li span:hover{color: #6ba968; font-weight: bold; border-bottom: 2px solid #6ba968; padding-bottom: 10px;}
.active{color: #6ba968; font-weight: bold; border-bottom: 2px solid #6ba968; padding-bottom: 10px;}
.mobile button, .onlymobile{display: none;}
.logomobile, .hide{display: none;}
/*Submenus content*/
.submenu, .submenudesk{display: block}
.submenu li a {text-transform: none; line-height: 30px;}
.submenudesk li a{text-transform: none; line-height: 20px;}
.submenu li a:hover, .submenudesk li a:hover{border: none; margin-bottom: 0;}
.submenudesk{width: 190px; position: absolute; top: 31px; padding: 0px; background: #fff; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
z-index: 2;}
.submenudesk li{padding: 5px;}


/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  header ul{font-size: 13px;}
  .logo{padding: 5px 0px;}
  .Header{height: 218px};
}

@media (min-width: 1200px) and (max-width: 1399px){
  header ul{font-size: 13px;}
  .Header{height: 218px};
  .logo{padding: 5px 0px; width: 207px;}
  .destaque{width: 413px; height: auto;}
  .destaque img{width: 100%;}
}

@media (max-width: 1199px){
  .Header{height: auto};
  .mobile{display: grid; grid-template-columns: 40px 1fr; margin: 0 10px; align-items: center; justify-items: center;}
  .mobile button{display: block;}
  .logo{margin: 0;}
  .headerMobileActive{height: auto;}
  .headerMobileInative ul{display: none;}
  header ul{flex-direction: column;}
  header ul li, header ul li span{padding: 0px 0px 20px 0px;}
  header ul li a:hover, header ul li span:hover{border: 0; padding: 0;}
  .desk{display: none !important;}
  .onlymobile{display: block; padding-top: 0px;}
  .submenudesk{display: none;}
  .submenu li, .submenu{padding: 0;}
  .onlymobile a {padding-right: 20px;}
}

@media (max-width: 767px){
  header{position: sticky !important; top: 0px; z-index: 2; background: #ffffff;}
  .logo {width: 180px; padding: 10px 0px;}
  .logo img {width: 90%;}
  .onlymobile{display: block;}
  .onlymobile a{padding-right: 20px !important;}
  .desk{display: none !important;}
}

@media (max-width: 540px){
  #g-recaptcha {
    transform: initial;
  }
}

@media (max-width: 360px){
  #g-recaptcha {
    transform: scale(0.75);
    margin-bottom: 10px;
  }

  #g-recaptcha div{
    width: 224px;
  }
}
