#servicosPage{padding: 44px 0px; position: relative;}
.servicosBanner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: 0.766fr 1fr;
align-items: center;}
.servicosBanner .bannerMenu{top: 0px;}
.servicosBanner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*Servicos*/
#servicos{padding: 50px 0px 0px 0px;}
/*Responsivo*/
@media (min-width: 1200px) and (max-width: 1399px){
  .accordionList div{font-size: 18px;}
  .accordionList li b{font-size: 19px;}
}
@media(max-width: 1199px){
  .accordionList div{font-size: 16px;}
  .accordionList li b{font-size: 17px;}
}
@media (max-width: 767px){
  .servicosBanner{grid-template-columns: 1fr; text-align: center;}
}
