.temNoParqueBanner {
 color: #fff;
 font-family: 'Montserrat', sans-serif;
}
.banner {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 height: 100%;
}
.temNoParqueFilter img {
 width: 100%;
 height: auto;
 object-fit: cover;
 position: relative;
 z-index: -1;
}
.banner h1 {
 font-size: 60px;
 line-height: 73px;
 font-weight: 700;
 text-transform: uppercase;
 margin: 15px 0px;
 position: absolute;
 z-index: 2;
}
.banner hr {
 background-color: #ffffff;
 color: #ffffff;
 border: none;
 height: 1px;
 margin-bottom: 20px;
}
.banner p {
 max-width: 786px;
 font-size: 22px;
 line-height: 27px;
}
#temNoParque {
 padding: 15px 0px;
}
.temNoParqueContent {
 display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 10px;
 align-items: center;
 text-align: left;
 justify-items: end;
 font-family: 'Montserrat', sans-serif;
}
.temNoParqueContent p,
.temNoParqueContent strong,
.temNoParqueContent span,
.temNoParqueContent a {
 color: #485860 !important;
 font-size: 18px !important;
 line-height: 25px !important;
 padding-bottom: 20px  !important;
 background-color: transparent !important;
 font-family: 'Montserrat', sans-serif !important;
}
.temNoParqueContent a{
  text-decoration-line: underline !important;
}
/*box*/
.temNoParqueBoxFilter {
 height: 100%;
 background: rgba(0, 0, 0, 0.3);
}
.temNoParqueBoxContent {
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 gap: 10px;
 font-family: 'Montserrat', sans-serif;
}
.temNoParqueBoxContent div {
 height: 320px;
 background-repeat: no-repeat;
}
.temNoParqueBoxContent .card {
 max-width: 470px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-end;
 padding-left: 20px;
}
.temNoParqueBoxContent h1 {
 font-size: 30px;
 line-height: 60px;
 font-weight: 700;
 text-transform: uppercase;
 color: #fff;
}
.gallery {
 //display: flex;
 //flex-direction: row;
 gap: 20px;
 margin: 20px 0 40px 0;
 grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
 display: grid;
}

.gallery div {
 width: 500px;
 height: 500px;
}

.gallery div img {
 width: 100%;
 height: 100%;
 object-fit: cover;
 cursor: pointer;
}

.videos {
 //display: flex;
 //flex-direction: row;
 gap: 20px;
 margin: 20px 0 40px 0;
 grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
 display: grid;
}

.videos div {
 position: relative;
 overflow: hidden;
 width: 100%;
 padding-top: 56.25%;
}

.videos div iframe {
 position: absolute;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
 width: 100%;
 height: 100%;
}

.galleryTitle {
 color: #485860;
 font-size: 18px;
 line-height: 25px;
 font-family: Montserrat, sans-serif;
 margin: 20px 0;
}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px) {
 .banner {
  max-width: 1600px;
 }
 .banner h1 {
  font-size: 50px;
  line-height: 60px;
 }
 .banner p {
  font-size: 16px;
 }
 .temNoParqueContent p,
 .temNoParqueContent strong,
 .temNoParqueContent span {
  font-size: 16px !important;
 }
 .temNoParqueContent img {
  object-fit: cover;
  height: auto;
 }
 .temNoParqueBoxContent div {
  height: 33vh;
  background-size: cover;
 }
 .temNoParqueBoxContent .card {
  padding-left: 10px;
  max-width: 100%;
 }
 .temNoParqueBoxContent h1 {
  font-size: 23px;
 }
}
@media (min-width: 1200px) and (max-width: 1399px) {
 .banner {
  max-width: 1400px;
 }
 .banner h1 {
  font-size: 50px;
  line-height: 60px;
 }
 .banner p {
  font-size: 16px;
 }
 .temNoParqueContent p,
 .temNoParqueContent strong,
 .temNoParqueContent span {
  font-size: 16px !important;
 }
 .temNoParqueContent img {
  object-fit: cover;
  height: auto;
 }
 .temNoParqueBoxContent div {
  height: 33vh;
  background-size: cover;
 }
 .temNoParqueBoxContent .card {
  padding-left: 10px;
  max-width: 100%;
 }
 .temNoParqueBoxContent h1 {
  font-size: 20px;
 }
}
@media (max-width: 1199px) {
 .banner {
  max-width: 100%;
 }
 .banner h1 {
  font-size: 2em;
  line-height: 33px;
  text-align: center;
 }
 .banner p {
  font-size: 16px;
 }
 .temNoParqueBoxContent {
  grid-template-columns: repeat(2, 1fr);
 }
 .temNoParqueContent p,
 .temNoParqueContent strong,
 .temNoParqueContent span {
  font-size: 14px !important;
 }
 .temNoParqueContent img {
  object-fit: cover;
  height: auto;
 }
 .temNoParqueBoxContent div {
  height: 53vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
 }
 .temNoParqueBoxContent .card {
  padding-left: 10px;
  max-width: 100%;
 }
 .temNoParqueBoxContent h1 {
  font-size: 2em;
  line-height: 50px;
 }
}

@media (max-width: 991px) {
 .temNoParqueContent {
  grid-template-columns: 1fr;
 }
 .temNoParqueContent img {
  display: none;
 }
}

@media (max-width: 767px) {
 .temNoParqueBoxContent {
  grid-template-columns: 1fr;
 }
 .temNoParqueBoxContent h1 {
  font-size: 1.3em;
  line-height: 30px;
 }
 .temNoParqueBoxContent div {
  height: 50vh !important;
 }

  .videos {
    grid-template-columns: 1fr !important;
  }
 .gallery div {
  width: 100%;
  height: auto;
 }
}
