*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body {
  height: 100%;
  margin: 0;
}

body{
  color: #fff;
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif; 
}

.container{
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 10px;
}

#root{
  min-height: calc(100vh - 70px);
}

ul{
  list-style: none;
}

li {
  cursor: pointer;
}

a{
  text-decoration: none;
}

button{
  outline: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif; 
}

section {
  padding: 15px 0px;
}

hr{
  background-color:#e8e8e8; 
  color:#e8e8e8; 
  border: none; 
  height: 1px;
}


header ul{
  font-family: 'Montserrat', sans-serif; 
  font-size: 15px; 
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  padding: 10px 0px;
}

header ul li{
  position: relative;
}

header ul li a, 
header ul li span{
  text-transform: uppercase; 
  color: #485860;
}

header ul li svg{
  position: relative; 
  top: 2px; 
  left: 0px;
}

header ul li a:hover, 
header ul li span:hover{
  color: #6ba968; 
  font-weight: bold; 
  border-bottom: 2px solid #6ba968; 
  padding-bottom: 10px;
}

.swiper-container .swiper-slide img{
  display: block;
    width: 100%;
}

main {
  margin-top: 218px;
}

footer{
  padding: 60px 0px 0px 0px; 
  position: relative; 
  width: 100%; 
  //bottom: -218px;
  bottom: 0px;
}

.banner-menu{
  font-size: 15px;  
  position: relative; 
  top: 44px;
}

::-webkit-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
:-ms-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
::placeholder {color:#485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
select {color:#485860; font-size: 25px !important; font-family: 'Montserrat', sans-serif;}

input[type="radio"] {border: 0px;width: 100%;height: 0.7em; position: relative; top: 3px; }
input[type='radio']:checked:after {width: 18px;height: 18px;border-radius: 15px;top: -6px;left: 1px;
position: relative;background-color: #508154;content: '';display: inline-block;visibility: visible;}

input[type=checkbox] {transform: scale(1.5); position: relative; top: 4px;}
input[type=checkbox]:checked:after {width: 14px;height: 14px;border-radius: 0px;top: -10px;left: 3px;
position: relative;background-color: #508154;content: ''; color: #fff; display: inline-block;visibility: visible;}

.swiper-pagination-bullet-active{background: #ffffff !important;}
.swiper-pagination-bullet{background: #ffffff !important;}

.errorCpf {text-align: right; color: red; padding-top: 5px; font-size: 13px;}
.errorCpf input{border-bottom: 1px solid red !important; color: red !important;}
.errorCpf label {top: 35% !important;}
.errorCpf label svg{color: red !important;}

.errorCnpj {text-align: right; color: red; padding-top: 5px; font-size: 13px;}
.errorCnpj input{border-bottom: 1px solid red !important; color: red !important;}
.errorCnpj label {top: 35% !important;}
.errorCnpj label svg{color: red !important;}

.sucesso {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){

  header ul{
    font-size: 13px;
  }

  .container{
    max-width: 1600px;
    margin: 0 10px;
  }  

  main {
    margin-top: 218px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px){

  header ul{
    font-size: 13px;
  }

  .container{
    max-width: 1400px;
    margin: 0 auto;
  }  

  main {
    margin-top: 218px;
  }

  ::-webkit-input-placeholder {font-size: 16px;}
  :-ms-input-placeholder {font-size: 16px;}
  ::placeholder {font-size: 16px;}

  select, option{font-size: 16px !important;}
}

@media (max-width: 1199px){

  main {
    margin-top: 178px;
  }

  header ul{
    flex-direction: column;
  }

  header ul li, 
  header ul li span{
    padding: 0px 0px 20px 0px;
  }

  header ul li a:hover, 
  header ul li span:hover{
    border: 0; padding: 0;
  }

  .container {
    max-width: 100%;
    margin: 0 10px;
  }

}

@media (max-width: 767px){

  main {
    margin-top: 129px;
  }

  header{
    position: sticky !important; 
    top: 0px; z-index: 2; 
    background: #ffffff;
  }

  ::-webkit-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
  :-ms-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
  ::placeholder {color:#818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
  select, option{font-size: 16px !important;}

 

}