#beneficiosPage{padding: 44px 0px; position: relative;}
.beneficiosBanner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: .766fr 1fr;
align-items: center;}
.beneficiosBanner .bannerMenu{top: 0px;}
.beneficiosBanner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*Form*/
#todosBeneficios{text-align: center;}
#todosBeneficios form{display: grid; grid-template-columns: 1fr 1fr 0.3fr; gap: 30px; padding: 0px 0px 54px 0px;}
#todosBeneficios form input, #todosBeneficios form select{font-size: 25px; color: #485860; font-family: 'Montserrat', sans-serif;
border: 0; border-bottom: 1px solid #485860; background-color: transparent;}
#todosBeneficios .formButton{background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
/*Conteúdo*/
.beneficiosLoadContent{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.beneficiosLoadContent div{cursor:pointer; border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase;}
.beneficiosLoadContent div p{font-size: 22px; font-weight: 700;}
.beneficiosLoadContent div span{font-size: 15px;}
.beneficiosLoadContent div:hover {border: 1px solid #6ba968;color: #6ba968;}
.beneficiosLoadContent div img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%);}
.beneficiosLoadContent div:hover img {-webkit-filter: grayscale(0%);-moz-filter: grayscale(0%);-o-filter: grayscale(0%);}
.logoClube {height: 18em; border: none !important; padding: 0px !important; align-items: center; display: flex;}
.autocomplete{font-family: 'Montserrat', sans-serif; text-align: left; color: #485860; font-size: 18px; border: 1px solid #485860;
  padding: 0px; position: relative; top: -31px;}
  .autocomplete div span{padding-left: 5px;}
  .autocomplete div:hover{background-color: #485860; color: #ffffff; cursor: pointer;}
#todosBeneficios {text-align: center;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .banner{max-width: 1400px;}
  .beneficiosLoadContent div p {font-size: 18px;}
  .beneficiosLoadContent div span {font-size: 15px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
  .banner{max-width: 1200px;}
  .beneficiosLoadContent div p {font-size: 16px;}
  .beneficiosLoadContent div span {font-size: 14px;}
}
@media(max-width: 1199px){
  .banner{max-width: 100%; margin: 0 10px;}
  .beneficiosBanner{grid-template-columns: 1fr; text-align: center;}
  .beneficiosLoadContent div p {font-size: 16px;}
  .beneficiosLoadContent div span {font-size: 14px;}
}

@media (min-width: 992px) and (max-width: 1199px){
  #todosBeneficios form{gap: 10px;}
}

@media (min-width: 768px) and (max-width: 991px){
  .beneficiosLoadContent{grid-template-columns: repeat(2, 1fr);}
  #todosBeneficios form{grid-template-columns: 1fr;}
  #todosBeneficios form .formButton {width: 100%;}
}

@media (max-width: 767px){
  .beneficiosBanner{grid-template-columns: 1fr; text-align: center;}
  .beneficiosBanner h1{ font-size: 30px;line-height: 35px;}
  .beneficiosLoadContent{grid-template-columns: 1fr}
  #todosBeneficios form{grid-template-columns: 1fr;}
  #todosBeneficios form .formButton {width: 100%;}
}
