
.gastronomiaBanner{color: #fff; font-family: 'Montserrat', sans-serif;}
.banner{display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;}
.gastronomiaFilter img{width:100%; height:auto; object-fit: cover; position: relative; z-index: -1;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px; position: absolute; z-index: 2;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p {max-width: 786px;font-size: 22px;line-height: 27px;margin-bottom: 20px;}
.banner h1, .banner span {text-transform: uppercase;}
.banner span {font-size: 18px;line-height: 22px;}
.gastronomiaBanner .shoppingInfo span {border-left: 1px solid #fff;height: 40px;}
.gastronomiaBanner .shoppingInfo{display: flex; justify-content: flex-start; gap: 12px;}
.gastronomiaBanner .shoppingInfo a{color: #ffffff;}
.horario a{text-decoration: underline; font-weight: bold;}
.bannerIcon{position: relative; left: 0px; top: 5px;}
/*Form*/
#todasGastronomia form{display: grid; grid-template-columns: 1fr 1fr 0.3fr; gap: 30px; padding: 0px 0px 54px 0px;}
#todasGastronomia form input, #todasGastronomia form select{font-size: 25px; color: #485860; font-family: 'Montserrat', sans-serif;
border: 0; border-bottom: 1px solid #485860; background-color: transparent;}
#todasGastronomia .formButton{background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
/*Conteúdo*/
.gastronomiaLoadContent{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.gastronomiaLoadContent div{border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase; cursor: pointer;}
.gastronomiaLoadContent div:hover{border: 1px solid #6ba968; color: #6ba968;}
.gastronomiaLoadContent div p{font-size: 22px; font-weight: 700;}
.gastronomiaLoadContent div span{font-size: 15px;}

.gastronomiaLoadContent div:hover img{-webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%);}
.logo_restaurante {height: 18em; border: none !important; padding: 0px !important; align-items: center; display: flex;}
.logo_restaurante img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%);}
.autocomplete{font-family: 'Montserrat', sans-serif; text-align: left; color: #485860; font-size: 18px; border: 1px solid #485860;
padding: 0px; position: relative; top: -31px;}
.autocomplete div span{padding-left: 5px;}
.autocomplete div:hover{background-color: #485860; color: #ffffff; cursor: pointer;}

#todasGastronomia {text-align: center;}
/*Modal*/
.modal-bg{position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: rgba(0,0,0,0.9); z-index: 99999;
  display: none;}
.modal{position: relative; top: 50px; width: 1000px; height: 600px; background: #ffffff; border: 1px solid #000; margin: 0 auto;}
.show-modal{display: block;}
.modal-content{display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; 
    color: #485860; font-family: 'Montserrat', sans-serif; align-items: center; height: 100%;}
.modal-content .logo{text-align: center;}
.modal-content img{max-width: 230px; margin: 0 auto;}
.modal-content .info{text-align: left;}
.modal-content .info .categoria{max-width: 400px;}
.modal-content .info a{color: #485860;}
.modal-icon{cursor: pointer; position: absolute; right: 10px; top: 10px;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .banner{max-width: 1600px;}
  .banner h1{font-size: 50px; line-height: 60px;}
  .banner p {font-size: 16px;}
  .gastronomiaLoadContent div p {font-size: 18px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
  .banner{max-width: 1400px;}
  .banner h1{font-size: 50px; line-height: 60px;}
  .banner p {font-size: 16px;}
  .gastronomiaLoadContent div p {font-size: 16px;}
}
@media(max-width: 1199px){
  .banner{max-width: 100%;}
  .banner h1{font-size: 2em; line-height: 35px;}
  .banner p {font-size: 22px;}
  .gastronomiaLoadContent div p {font-size: 16px;}
  .gastronomiaLoadContent div span {font-size: 12px;}
  .gastronomiaBanner .shoppingInfo{display: flex; justify-content: space-between; gap: 0px; max-width: 650px;}
}

@media (min-width: 992px) and (max-width: 1199px){
  #todasGastronomia form{gap: 10px;}
}

@media (min-width: 768px) and (max-width: 991px){
  .gastronomiaLoadContent{grid-template-columns: repeat(2, 1fr);}
  .br_mobile{display: none;}
  #todasGastronomia form{grid-template-columns: 1fr;}
  #todasGastronomia .formButton{justify-self: center; width: 100%;}
  .modal{width: 700px;}
  .modal-content h1{font-size: 20px;}
  .modal-content p{font-size: 16px;}
}
@media (max-width: 767px){
  .banner span {font-size: 14px;}
  .banner button {width: 100%;height: 45px;font-size: 14px;}
  .banner button a{height: 43px;}
  .gastronomiaBanner .shoppingInfo {display: none;}
  #todasGastronomia form{grid-template-columns: 1fr;}
  .gastronomiaLoadContent{grid-template-columns: 1fr;}
  #todasGastronomia form input, #gastronomia-lojas form select{width: 100%;}
  #todasGastronomia form .formButton {width: 100%;}
  .gastronomia-banner {height: 70vh;}
  .modal-content{grid-template-columns: 1fr; gap: 10px; height: 90%;}
  .modal{width: 90%;}
  .modal-content .logo{max-width: 230px; margin: 0 auto;}
  .modal-content .info .categoria {max-width: 100%;}
  .modal-content h1{font-size: 18px;}
  .modal-content p{font-size: 16px;}
  .modal-content .info {text-align: center;padding: 0px 20px;}
}
    