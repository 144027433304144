.institucionalBanner{color: #fff; font-family: 'Montserrat', sans-serif;}
.institucionalFilter img{width:100%; height:auto; object-fit: cover; position: relative; z-index: -1;}
.banner{display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px; position: absolute; z-index: 2;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p{max-width: 786px;font-size: 22px; line-height: 27px;}
#informativo p, #informativo strong,  #informativo span{color: #485860 !important;font-size: 18px !important;line-height: 25px !important;max-width: 100%;padding-bottom: 0px !important; font-family: 'Montserrat', sans-serif !important;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1600px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
#informativo p, #informativo strong, #informativo span{font-size: 16px !important;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
#informativo p, #informativo strong, #informativo span{font-size: 16px !important;}
}
@media(max-width: 1199px){
.banner{max-width: 100%;}
.banner h1{font-size: 2em; line-height: 36px;}
.banner p{font-size: 16px;}
#informativo p, #informativo strong, #informativo span{font-size: 14px !important;}
}

@media (max-width: 767px){
#informativo p, #informativo strong, #informativo span{font-size: 14px !important;}
}